<template>
    <div>
        This is the redirect to ContactUs page.
    </div>
</template>
<script>
export default {
    name: "ContactUs",
}
</script>
<style lang="scsss" scoped>

</style>